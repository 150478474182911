import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home/Home";
import axios from "axios";
import WaitlistForm from "./components/WaitlistForm/WaitlistForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Terms from "./components/static/Terms";
import PrivacyPolicy from "./components/static/PrivacyPolicy";
import EAP from "./components/EAP/EAP";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/waitlist",
		element: <WaitlistForm />,
	},
	{
		path: "/terms",
		element: <Terms />,
	},
	{
		path: "/privacy",
		element: <PrivacyPolicy />,
	},
	{
		path: "/eap",
		element: <EAP />,
	},
]);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
		<ToastContainer />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

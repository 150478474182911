import React from "react";
import { Helmet } from "react-helmet";

const Terms = () => {
	return (
		<>
			<Helmet>
				<title>Terms and Conditions | ShakespeareAE</title>
			</Helmet>
			<div
				style={{
					margin: "8px",
				}}
			>
				<h2 id="-terms-and-conditions-">
					<strong>Terms and Conditions</strong>
				</h2>
				<p>
					<strong>Welcome to ShakespeareAE!</strong>
				</p>
				<p>
					<strong>
						These terms and conditions outline the rules and regulations for the
						use of ShakespeareAE.
					</strong>
				</p>
				<p>
					<strong>
						By using this app we assume you accept these terms and conditions.
						Do not continue to use ShakespeareAE if you do not agree to take all
						of the terms and conditions stated on this page.
					</strong>
				</p>
				<p>
					<strong>
						Users are requested to carefully read the Terms of Use before using
						the Platform or registering on the Platform or accessing any
						material or information through the Platform. Through the continued
						use of the Platform, or accepting these Terms of Use at the time of
						creating an Account, Users accept these Terms of Use and agree to be
						legally bound by the same. Use of and access to the Platform is
						offered to Users upon the condition of acceptance of all the terms,
						conditions and notices contained in these Terms of Use and Privacy
						Policy, along with any amendments made by Shakespeare AE at its sole
						discretion and posted on the Platform from time to time and subject
						to the above, Shakespeare AE grants Users a personal, non-exclusive,
						non-transferable, limited privilege to enter and use the Platform
						and the Shakespeare AE Services.
					</strong>
				</p>
				<p>
					<strong>
						The following terminology applies to these Terms and Conditions,
						Privacy Statement and Disclaimer Notice and all Agreements:
						&quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to
						you, the person log on this website and compliant to the Company’s
						terms and conditions. &quot;The Company&quot;,
						&quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and
						&quot;Us&quot;, refers to our Company. &quot;Party&quot;,
						&quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client
						and ourselves.
					</strong>
				</p>
				<h3 id="-license-">
					<strong>License</strong>
				</h3>
				<p>
					<strong>
						Unless otherwise stated, ShakespeareAE and/or its licensors own the
						intellectual property rights for all material on ShakespeareAE. All
						intellectual property rights are reserved. You may access this from
						ShakespeareAE for your own personal use subjected to restrictions
						set in these terms and conditions.
					</strong>
				</p>
				<p>
					<strong>You must not:</strong>
				</p>
				<p>
					<strong>
						Republish material from ShakespeareAESell, rent or sub-license
						material from ShakespeareAEReproduce, duplicate or copy material
						from ShakespeareAERedistribute content from ShakespeareAE
					</strong>
				</p>
				<p>
					<strong>This Agreement shall begin on the date hereof.</strong>
				</p>
				<p>
					<strong>
						Parts of this app offer an opportunity for users to post and
						exchange opinions and information in certain areas of the website.
						ShakespeareAE does not filter, edit, publish or review Comments
						prior to their presence on the website. Comments do not reflect the
						views and opinions of ShakespeareAE, its agents and/or affiliates.
						Comments reflect the views and opinions of the person who post their
						views and opinions. To the extent permitted by applicable laws,
						ShakespeareAE shall not be liable for the Comments or for any
						liability, damages or expenses caused and/or suffered as a result of
						any use of and/or posting of and/or appearance of the Comments on
						this website.
					</strong>
				</p>
				<p>
					<strong>
						ShakespeareAE reserves the right to monitor all Comments and to
						remove any Comments which can be considered inappropriate, offensive
						or causes breach of these Terms and Conditions.
					</strong>
				</p>
				<p>
					<strong>You warrant and represent that:</strong>
				</p>
				<p>
					<strong>
						You are entitled to post the Comments on our App and have all
						necessary licenses and consents to do so; The Comments do not invade
						any intellectual property right, including without limitation
						copyright, patent or trademark of any third party; The Comments do
						not contain any defamatory, libelous, offensive, indecent or
						otherwise unlawful material which is an invasion of privacy. The
						Comments will not be used to solicit or promote business or custom
						or present commercial activities or unlawful activity.
					</strong>
				</p>
				<p>
					<strong>
						You hereby grant ShakespeareAE a non-exclusive license to use,
						reproduce, edit and authorize others to use, reproduce and edit any
						of your Comments in any and all forms, formats or media.
					</strong>
				</p>
				<h3 id="eligibility">Eligibility</h3>
				<p>
					<strong>
						a. The Platform, and by extension, the Shakespeare AE Services, are
						not available to minors i.e. persons under the age of 18 (eighteen)
						years or to any Users suspended or removed by Shakespeare AE from
						accessing the Platform for any reason whatsoever. Users represent
						that they are of legal age to form a binding contract and are not a
						person barred from receiving, using, availing or accessing the
						Platform, and by extension, the Shakespeare AE Services under
						applicable law.
					</strong>
				</p>
				<p>
					<strong>
						b. Shakespeare AE reserves the right to refuse access to the
						Platform, at any time to new Users or to terminate or suspend access
						granted to existing Users at any time without any reasons for doing
						so.
					</strong>
				</p>
				<p>
					<strong>
						c. Users shall not have more than 1 (one) active Account (as defined
						below) on the Platform. Additionally, Users are prohibited from
						selling, trading, or otherwise transferring their Account to another
						party or impersonating any other person for the purpose of creating
						an Account with the Platform.
					</strong>
				</p>
				<h3 id="registration-">Registration:</h3>
				<p>
					<strong>
						a. Subject to the acceptance of all the terms, conditions and
						notices contained in these Terms of Use and Privacy Policy, along
						with any amendments made by Shakespeare AE at its sole discretion
						and posted on the Platform from time to time and subject to the
						Users’ registration on the Platform through the creation of an
						Account (as defined below), Users are granted a personal,
						non-exclusive, non-transferrable, and limited right to access the
						Platform, avail the Shakespeare AE Services and connect with
						Merchants and Delivery Partners on the Platform. A User’s ability to
						continue using the Platform and Shakespeare AE Services is subject
						to their continued maintenance of an Account on the Platform.
					</strong>
				</p>
				<p>
					<strong>
						b. In order to use the Platform and avail the Shakespeare AE
						Services, Users will have to register on the Platform and create an
						account with a unique user identity and password (“Account”). Users
						will be required to enter their personal information including their
						name, contact details, email address and valid phone number while
						registering an Account. As part of the registration, Users may be
						required to undertake a verification process to verify the
						information provided.
					</strong>
				</p>
				<p>
					<strong>
						c. Users agree and accept that the information provided by the User
						for the creation of their Account is complete, true, accurate and
						up-to-date. In the event of any change to such information, Users
						are required to immediately update their Account information. Users
						acknowledge and accept that Shakespeare AE has not independently
						verified the information and Shakespeare AE shall in no way be
						responsible or liable for the accuracy or completeness of any
						information provided by Users. If Users provide any information that
						is untrue, inaccurate, not current or incomplete, or Shakespeare AE
						has reasonable grounds to suspect that such information is untrue,
						inaccurate, not current or incomplete, Shakespeare AE reserves the
						right to suspend or terminate the respective User Account and refuse
						any and all current or future use of the Platform (or any portion
						thereof) through such User Account, at any time.
					</strong>
				</p>
				<p>
					<strong>
						d. Users are responsible for maintaining the confidentiality of the
						Account information, and are fully responsible for all activities
						that occur through their Account. Users agree to (i) immediately
						notify Shakespeare AE of any unauthorized use of their Account,
						related information or any other breach of security, and (ii) ensure
						that they exit from their Account at the end of each session.
						Shakespeare AE cannot and will not be liable for any loss or damage
						arising from User failure to comply with this provision. Users may
						be held liable for losses incurred by Shakespeare AE or any other
						User of or visitor to the Platform due to authorized or unauthorized
						use of their Account as a result of User failure in keeping their
						Account information secure and confidential. Use of another User’s
						Account information is expressly prohibited.
					</strong>
				</p>
				<p>
					<strong>
						e. Shakespeare AE shall use User location-based information that is
						captured by Shakespeare AE through the global positioning system
						when they use their mobile device or computer to request a
						Shakespeare AE Service on the Platform. Such location based
						information shall be used by Shakespeare AE only to facilitate and
						improve the Shakespeare AE Services being offered to Users.
					</strong>
				</p>
				<p>
					<strong>
						f. In the case where the Platform is unable to establish a unique
						identity of the User against a valid mobile number or e-mail
						address, the Account shall be indefinitely suspended. Shakespeare AE
						reserves the full discretion to suspend a User&#39;s Account in the
						above event and does not have the obligation/liability to share any
						Account information whatsoever.
					</strong>
				</p>
				<h3 id="representations-and-warranties-">
					Representations and Warranties:
				</h3>
				<p>
					<strong>
						a. Subject to compliance with the Terms of Use, Shakespeare AE
						grants Users a non-exclusive, limited privilege to access and use
						this Platform and the Shakespeare AE Services.
					</strong>
				</p>
				<p>
					<strong>
						b. Users agree to use the Platform only: (i) for purposes that are
						permitted by this Terms of Use; and (ii) in accordance with any
						applicable law, regulation or generally accepted practices or
						guidelines. Users agree not to engage in activities that may
						adversely affect the use of the Platform by Shakespeare AE or
						Delivery Partner(s) or Merchants or other Users.
					</strong>
				</p>
				<p>
					<strong>
						c. Users represent and warrant that they have not received any
						notice from any third party or any governmental authority and no
						litigation is pending against them in any court of law, which
						prevents them from accessing the Platform and/or availing the
						Shakespeare AE Services.
					</strong>
				</p>
				<p>
					<strong>
						d. Users represent and warrant that they are legally authorised to
						view and access the Platform and avail the Shakespeare AE Services.
					</strong>
				</p>
				<p>
					<strong>
						e. Users agree not to access (or attempt to access) the Platform by
						any means other than through the interface that is provided by
						Shakespeare AE. Users shall not use any deep-link, robot, spider or
						other automatic device, program, algorithm or methodology, or any
						similar or equivalent manual process, to access, acquire, copy or
						monitor any portion of the Platform, or in any way reproduce or
						circumvent the navigational structure or presentation of the
						Platform, materials or any Shakespeare AE Property, to obtain or
						attempt to obtain any materials, documents or information through
						any means not specifically made available through the Platform.
					</strong>
				</p>
				<p>
					<strong>
						f. Users acknowledge and agree that by accessing or using the
						Platform, they may be exposed to content from others that they may
						consider offensive, indecent or otherwise objectionable. Shakespeare
						AE disclaims all liabilities arising in relation to such offensive
						content on the Platform.
					</strong>
				</p>
				<p>
					<strong>g. Further, Users undertake not to:</strong>
				</p>
				<ul>
					<li>
						<strong>
							defame, abuse, harass, threaten or otherwise violate the legal
							rights of others;
						</strong>
					</li>
					<li>
						<strong>
							publish, post, upload, distribute or disseminate any
							inappropriate, profane, defamatory, infringing, disparaging,
							ethnically objectionable, obscene, indecent or unlawful topic,
							name, material or information;
						</strong>
					</li>
					<li>
						<strong>do any such thing that may harms minors in any way;</strong>
					</li>
					<li>
						<strong>
							copy, republish, post, display, translate, transmit, reproduce or
							distribute any Shakespeare AE Property through any medium without
							obtaining the necessary authorization from Shakespeare AE;
						</strong>
					</li>
					<li>
						<strong>
							conduct or forward surveys, contests, pyramid schemes or chain
							letters;
						</strong>
					</li>
					<li>
						<strong>
							upload or distribute files that contain software or other material
							protected by applicable intellectual property laws unless such
							Users own or control the rights thereto or have received all
							necessary consents;
						</strong>
					</li>
					<li>
						<strong>
							upload or distribute files or documents or videos (whether live or
							pre-recorded) that contain viruses, corrupted files, or any other
							similar software or programs that may damage the operation of the
							Platform or another&#39;s computer;
						</strong>
					</li>
					<li>
						<strong>
							engage in any activity that interferes with or disrupts access to
							the Platform (or the servers and networks which are connected to
							the Platform);
						</strong>
					</li>
					<li>
						<strong>
							attempt to gain unauthorized access to any portion or feature of
							the Platform, any other systems or networks connected to the
							Platform, to any Shakespeare AE server, or through the Platform,
							by hacking, password mining or any other illegitimate means;
						</strong>
					</li>
					<li>
						<strong>
							probe, scan or test the vulnerability of the Platform or any
							network connected to the Platform, nor breach the security or
							authentication measures on the Platform or any network connected
							to the Platform. Users may not reverse look-up, trace or seek to
							trace any information on any other User, of or visitor to, the
							Platform, to its source, or exploit the Platform or information
							made available or offered by or through the Platform, in any way
							whether or not the purpose is to reveal any information, including
							but not limited to personal identification information, other than
							Your own information, as provided on the Platform;
						</strong>
					</li>
					<li>
						<strong>
							disrupt or interfere with the security of, or otherwise cause harm
							to, the Platform, systems resources, accounts, passwords, servers
							or networks connected to or accessible through the Platform or any
							affiliated or linked sites;
						</strong>
					</li>
					<li>
						<strong>
							collect or store data about other Users, Merchants, Delivery
							Partner in connection with the prohibited conduct and activities
							set forth herein;
						</strong>
					</li>
					<li>
						<strong>
							use any device or software to interfere or attempt to interfere
							with the proper working of the Platform or any transaction being
							conducted on the Platform, or with any other person’s use of the
							Platform;
						</strong>
					</li>
					<li>
						<strong>
							use the Platform or any material or Shakespeare AE Property for
							any purpose that is unlawful or prohibited by these Terms of Use,
							or to solicit the performance of any illegal activity or other
							activity which infringes the rights of the Company or other third
							parties;
						</strong>
					</li>
					<li>
						<strong>
							falsify or delete any author attributions, legal or other proper
							notices or proprietary designations or labels of the origin or
							source of software or other material contained in a file that is
							uploaded;
						</strong>
					</li>
					<li>
						<strong>
							impersonate any other User, Merchant, Delivery Partner or person;
						</strong>
					</li>
					<li>
						<strong>
							violate any applicable laws or regulations for the time being in
							force within or outside India or anyone’s right to privacy or
							personality;
						</strong>
					</li>
					<li>
						<strong>
							violate the Terms of Use contained herein or elsewhere;
						</strong>
					</li>
					<li>
						<strong>
							threatens the unity, integrity, defence, security or sovereignty
							of India, friendly relation with foreign states, or public order
							or causes incitement to the commission of any cognisable offence
							or prevents investigation of any offence or is insulting for any
							other nation; and
						</strong>
					</li>
					<li>
						<strong>
							reverse engineer, modify, copy, distribute, transmit, display,
							perform, reproduce, publish, license, create derivative works
							from, transfer, or sell any information or software obtained from
							the Platform.
						</strong>
					</li>
				</ul>
				<p>
					<strong>
						h. Users agree and acknowledge that the use of the Shakespeare AE
						Services offered by Shakespeare AE is at their sole risk and that
						Shakespeare AE disclaims all representations and warranties of any
						kind, whether express or implied as to condition, suitability,
						quality, merchantability and fitness for any purposes are excluded
						to the fullest extent permitted by law.
					</strong>
				</p>
				<p>
					<strong>
						i. Without prejudice to the above, Shakespeare AE makes no
						representation or warranty that the Shakespeare AE Services will
						meet the Users requirements.
					</strong>
				</p>
				<p>
					<strong>
						j. All materials/content on the Platform (except any third party
						content available on the Platform), including, without limitation,
						names, logos, trademarks, images, text, columns, graphics, videos,
						photographs, illustrations, artwork, software and other elements
						(collectively, “Material”) are protected by copyrights, trademarks
						and/or other intellectual property rights owned and controlled by
						Shakespeare AE. Users acknowledge and agree that the Material is
						made available for limited, non-commercial, personal use only.
						Except as specifically provided herein or elsewhere in our Platform,
						no Material may be copied, reproduced, republished, sold,
						downloaded, posted, transmitted, or distributed in any way, or
						otherwise used for any purpose other than the purposes stated under
						this Terms of Use, by any person or entity, without Shakespeare AE’s
						prior express written permission. Users may not add, delete,
						distort, or otherwise modify the Material. Any unauthorized attempt
						to modify any Material, to defeat or circumvent any security
						features, or to utilize our Platform or any part of the Material for
						any purpose other than its intended purposes is strictly prohibited.
						Subject to the above restrictions under this Clause, Shakespeare AE
						hereby grants Users a non-exclusive, freely revocable (upon notice
						from Shakespeare AE), non-transferable access to view the Material
						on the Platform.
					</strong>
				</p>
				<h3 id="-intellectual-property-rights-">
					<strong>Intellectual Property Rights</strong>
				</h3>
				<p>
					<strong>
						a. The Platform and process, and their selection and arrangement,
						including but not limited to, all text, videos, graphics, user
						interfaces, visual interfaces, sounds and music (if any), artwork,
						algorithm and computer code (and any combination thereof),
						compilations and databases of Accounts and Account details, except
						any third party software available on the Platform, is owned by
						Shakespeare AE (“Shakespeare AE Property”) and the design,
						structure, selection, co-ordination, expression, look and feel and
						arrangement of such Shakespeare AE Property is protected by
						copyright, patent and trademark laws, and various other intellectual
						property rights. Users are not permitted to use Shakespeare AE
						Property without the prior written consent of Shakespeare AE.
					</strong>
				</p>
				<p>
					<strong>
						b. The trademarks, logos and service marks displayed on the Platform
						(“Marks”) are the property of Shakespeare AE, except any trademark,
						logos and service marks of third parties available on the Platform.
						Users are not permitted to use the Marks without the prior consent
						of Shakespeare AE or such third party as may be applicable.
					</strong>
				</p>
				<p>
					<strong>
						c. If any User believes the Platform violates their intellectual
						property, such User must promptly notify Shakespeare AE in writing
						at […]n These notifications should only be submitted by the owner of
						the intellectual property or an agent duly authorized to act on
						his/her behalf. However, any false claim by a User may result in the
						termination of such User’s access to the Platform. Users are
						required to provide the following details in their notice:
					</strong>
				</p>
				<ul>
					<li>
						<strong>
							the intellectual property that the User believe is being
							infringed;
						</strong>
					</li>
					<li>
						<strong>
							the item that the User think is infringing and include sufficient
							information about where the material is located on the Platform;
						</strong>
					</li>
					<li>
						<strong>
							a statement that the User believes in good faith that the item the
							User has identified as infringing is not authorized by the
							intellectual property owner, its agent, or the law to be used in
							connection with the Platform;
						</strong>
					</li>
					<li>
						<strong>
							the User’s contact details, such as the User’s address, telephone
							number, and/or email;
						</strong>
					</li>
					<li>
						<strong>
							a statement that the information provided in the User’s notice is
							accurate, and that the User is the intellectual property owner or
							an agent authorized to act on behalf of the intellectual property
							owner whose intellectual property is being infringed; and
						</strong>
					</li>
					<li>
						<strong>the User’s physical or electronic signature.</strong>
					</li>
				</ul>
				<h3 id="-disclaimer-of-warranties-liabilities-">
					<strong>Disclaimer of Warranties &amp; Liabilities</strong>
				</h3>
				<p>
					<strong>
						Users expressly understand and agree that, to the maximum extent
						permitted by applicable law:
					</strong>
				</p>
				<ol>
					<li>
						<strong>
							The Platform, and Shakespeare AE Services are provided by
							Shakespeare AE on an &quot;as is&quot; basis without warranty of
							any kind, express, implied, statutory or otherwise, including the
							implied warranties of title, non-infringement, merchantability or
							fitness for a particular purpose. Without limiting the foregoing,
							Shakespeare AE makes no warranty that (i) the Platform,
							Shakespeare AE Services will meet User requirements or use of the
							Platform will be uninterrupted, timely, secure or error-free; (ii)
							the quality of the Platform will meet User expectations; or (iii)
							any errors or defects in the Platform will be corrected. No advice
							or information, whether oral or written, obtained by Users from
							Shakespeare AE shall create any warranty not expressly stated in
							the Terms of Use.
						</strong>
					</li>
					<li>
						<strong>
							Shakespeare AE will have no liability related to any User content
							arising under intellectual property rights, libel, privacy,
							publicity, obscenity or other laws. Shakespeare AE also disclaims
							all liability with respect to the misuse, loss, modification or
							unavailability of any User content.
						</strong>
					</li>
					<li>
						<strong>
							Shakespeare AE will not be liable for any loss that Users may
							incur as a consequence of unauthorized use of their Account or
							Account information in connection with the Platform either with or
							without their knowledge.
						</strong>
					</li>
					<li>
						<strong>
							Shakespeare AE shall not be responsible for the delay or inability
							to use the Platform, Shakespeare AE Services or related
							functionalities, the provision of or failure to provide
							functionalities, or for any information, software, functionalities
							and related graphics obtained through the Platform, or otherwise
							arising out of the use of the Platform, whether based on contract,
							tort, negligence, strict liability or otherwise. Further,
							Shakespeare AE shall not be held responsible for non-availability
							of the Platform during periodic maintenance operations or any
							unplanned suspension of access to the Platform that may occur due
							to technical reasons or for any reason beyond Shakespeare AE&#39;s
							control. Users understand and agree that any material or data
							downloaded or otherwise obtained through the Platform is done
							entirely at their own discretion and risk, and that they will be
							solely responsible for any damage to their computer systems or
							loss of data that results from the download of such material or
							data.
						</strong>
					</li>
				</ol>
				<h3 id="-indemnification-and-limitation-of-liability-">
					<strong>Indemnification and Limitation of Liability</strong>
				</h3>
				<p>
					<strong>
						a. Users agree to indemnify, defend and hold harmless Shakespeare AE
						and its affiliates including but not limited to its officers,
						directors, consultants, agents and employees (“Indemnitees”) from
						and against any and all losses, liabilities, claims, damages,
						demands, costs and expenses (including legal fees and disbursements
						in connection therewith and interest chargeable thereon) asserted
						against or incurred by the Indemnitees that arise out of, result
						from, or may be payable by virtue of, any breach or non-performance
						of any obligation, covenant, representation or warranty by Users
						pursuant to these Terms of Use. Further, Users agree to hold the
						Indemnitees harmless against any claims made by any third party due
						to, or arising out of, or in connection with, such Users use of the
						Platform, Shakespeare AE Services, any misrepresentation with
						respect to the data or information provided by Users in relation to
						their Account, Users violation of the Terms of Use, or Users
						violation of any rights of another, including any intellectual
						property rights.
					</strong>
				</p>
				<p>
					<strong>
						b. In no event shall the Indemnitees be liable to Users or any third
						party for any special, incidental, indirect, consequential or
						punitive damages whatsoever, arising out of or in connection with
						their use of or access to the Platform or Shakespeare AE Services on
						the Platform.
					</strong>
				</p>
				<p>
					<strong>
						c. Users indemnification obligation under the Terms of Use will
						survive the termination of their Account or use of the Platform or
						Shakespeare AE Services.
					</strong>
				</p>
				<p>
					<strong>
						d. Subject to applicable laws, in no event will Shakespeare AE or
						its employees aggregate liability, arising from or related to the
						Shakespeare AE Services or the use of the Platform, exceed INR 100
						for any and all causes of actions brought by Users or on behalf of
						Users.
					</strong>
				</p>
				<p>
					<strong>
						e. The Platform and the Shakespeare AE Services are only available
						to Users located in India. Users shall not access or use the
						Platform from any other jurisdiction except for India. If a User
						accesses or uses the Platform from any other jurisdiction except for
						India, the User shall be liable to comply with all applicable laws
						and Shakespeare AE shall not be liable for the same, whatsoever.
					</strong>
				</p>
				<h3 id="-grievance-officer-">
					<strong>Grievance Officer</strong>
				</h3>
				<p>
					<strong>
						In accordance with Information Technology Act 2000 and rules made
						there under and the Consumer Protection (E-Commerce) Rules, 2020,
						the name and contact details of the Grievance Officer are provided
						below:
					</strong>
				</p>
				<p>
					<strong>
						Grievance Officer: […] Designation: […] Community Operations Email:
						[…]
					</strong>
				</p>
				<h3 id="-link-to-third-parties-">
					<strong>Link to Third Parties</strong>
				</h3>
				<p>
					<strong>
						The Platform may contain links to other sites owned by third parties
						(i.e. advertisers, affiliate partners, strategic partners, or
						others). Shakespeare AE shall not be responsible for examining or
						evaluating such third party websites, and Shakespeare AE does not
						warrant the products or offerings of, any of these businesses or
						individuals, or the accuracy of the content of such third party
						websites. Shakespeare AE does not assume any responsibility or
						liability for the actions, product, and content of any such third
						party websites. Before any Users use/access any such third-party
						websites, they should review the applicable terms of use and
						policies for such third party websites. If Users decide to access
						any such linked third party website, they do so at their own risk.
					</strong>
				</p>
				<h3 id="-term-and-termination-">
					<strong>Term and Termination</strong>
				</h3>
				<ol>
					<li>
						<strong>
							These Terms of Use will continue to apply until terminated by
							either the User or Shakespeare AE as set forth below.
						</strong>
					</li>
					<li>
						<strong>
							If the User objects to the Terms of Use or are dissatisfied with
							the Platform or Shakespeare AE Services, they can (i) close their
							Account through the Platform or by writing to […]; and (ii) stop
							accessing the Platform.
						</strong>
					</li>
					<li>
						<strong>
							Shakespeare AE may, in its sole discretion, bar any User’s use of
							the Platform or the Shakespeare AE Services at any time, for any
							or no reason. Even after an Account with Shakespeare AE is
							disabled, made dormant or inactive, these Users shall remain
							liable for all their uncleared dues with respect to the Merchant
							Services.
						</strong>
					</li>
					<li>
						<strong>
							Shakespeare AE may delist any User or block their future access to
							the Platform or suspend or terminate their Account if it believes,
							in its sole and absolute discretion that they have infringed,
							breached, violated, abused, or unethically manipulated or
							exploited any term of these Terms of Use or anyway otherwise acted
							unethically.
						</strong>
					</li>
					<li>
						<strong>
							Notwithstanding anything in this clause, these Terms of Use will
							survive indefinitely unless and until Shakespeare AE chooses to
							terminate them.
						</strong>
					</li>
					<li>
						<strong>
							Users hereby agree and acknowledge, upon termination, Shakespeare
							AE shall have the right to retain all information pertaining to
							the transactions initiated by Users on the Platform.
						</strong>
					</li>
				</ol>
				<h3 id="-governing-law-">
					<strong>Governing Law</strong>
				</h3>
				<p>
					<strong>
						These Terms of Use shall be governed by and constructed in
						accordance with the laws of India without reference to conflict of
						laws principles and disputes arising in relation hereto shall be
						subject to the exclusive jurisdiction of courts, tribunals, fora,
						and applicable authorities in […].
					</strong>
				</p>
				<h3 id="-report-abuse-">
					<strong>Report Abuse</strong>
				</h3>
				<p>
					<strong>
						In the event Users come across any abuse or violation of these Terms
						of Use or if they become aware of any objectionable content on the
						Platform, they can report the same to the following e-mail id: […]
						In case Users have any queries with respect to these Terms of Use or
						the Shakespeare AE Services, please write to […]
					</strong>
				</p>
				<h3 id="-communications-">
					<strong>Communications</strong>
				</h3>
				<p>
					<strong>
						The User hereby expressly agrees to receive communications by way of
						SMSs and/or e-mails and/or Whatsapp from Shakespeare AE, or other
						third parties. The User can unsubscribe/ opt-out from receiving
						communications through SMS, Whatsapp and email anytime either
						through the specific channels of communication or by contacting
						customer support, provided however, that Users may still receive
						communications with respect to their use of the Shakespeare AE
						Service.
					</strong>
				</p>
				<h3 id="-violation-of-the-terms-of-use-">
					<strong>Violation of the Terms of Use</strong>
				</h3>
				<p>
					<strong>
						Users agree that any violation by Users of these Terms of Use will
						constitute an unlawful and unfair business practice, and will cause
						irreparable harm to Shakespeare AE, as the case may be, for which
						monetary damages would be inadequate, and Users consent to the
						Shakespeare AE obtaining any injunctive or equitable relief that
						they deem necessary or appropriate in such circumstances. These
						remedies are in addition to any other remedies that the Shakespeare
						AE may have at law or in equity. If Shakespeare AE takes any legal
						action against Users as a result of their violation of these Terms
						of Use, Shakespeare AE is entitled to recover from Users , and Users
						agree to pay all reasonable attorneys’ fees and costs of such
						action, in addition to any other relief that may be granted.
					</strong>
				</p>
				<h3 id="-additional-terms-">
					<strong>Additional Terms</strong>
				</h3>
				<p>
					<strong>
						Shakespeare AE may also require Users to follow additional rules,
						guidelines, policies or other conditions in order to participate in
						certain promotions or activities available through the Platform (the
						“Policies”). These Policies shall form a part of these Terms of Use,
						and Users agree to comply with them when they participate in those
						promotions, or otherwise engage in activities governed by such
						additional terms.
					</strong>
				</p>
				<h3 id="-general-">
					<strong>General</strong>
				</h3>
				<p>
					<strong>
						a. Amendments: Shakespeare AE reserves the unconditional right to
						modify or amend this Terms of Use , Privacy Policy and Policies
						without any requirement to notify Users of the same. Users can
						determine when this Terms of Use was last modified by referring to
						the “Last Updated” legend above. It shall be the User’s
						responsibility to check these Terms of Use, Privacy Policy and
						Policies periodically for changes. The User’s acceptance of the
						amended Terms of Use or continued use of the Platform shall signify
						their consent to such changes and agreement to be legally bound by
						the same.
					</strong>
				</p>
				<p>
					<strong>
						b. Notice: All notices from Shakespeare AE will be served by email
						to the User’s registered email address or by general notification on
						the Platform.
					</strong>
				</p>
				<p>
					<strong>
						c. Assignment: Users cannot assign or otherwise transfer the Terms
						of Use, or any rights granted hereunder to any third party.
						Shakespeare AE’s rights under the Terms of Use are freely
						transferable by Shakespeare AE to any third party without the
						requirement of informing the User or seeking their consent.
					</strong>
				</p>
				<p>
					<strong>
						d. Severability: If, for any reason, a court of competent
						jurisdiction finds any provision of the Terms of Use, or any portion
						thereof, to be unenforceable, that provision shall be enforced to
						the maximum extent permissible so as to give effect to the intent of
						the parties as reflected by that provision, and the remainder of the
						Terms of Use shall continue in full force and effect.
					</strong>
				</p>
				<p>
					<strong>
						e. Waiver: Any failure by Shakespeare AE to enforce or exercise any
						provision of the Terms of Use, or any related right, shall not
						constitute a waiver by Shakespeare AE of that provision or right.
					</strong>
				</p>
				<p>
					<strong>
						f. Integration: These Terms of Use together with Shakespeare AE’s
						Privacy Policy and Policies and any other legal notices,
						communications published by Shakespeare AE on its Platform, and any
						other agreements executed between the User and Shakespeare AE shall
						constitute the entire agreement between the User and Shakespeare AE
						concerning its Platform, Shakespeare AE Services and governs the
						User’s use of the Platform and Shakespeare AE Service, superseding
						any prior agreements between the User and Shakespeare AE with
						respect to the Platform and Shakespeare AE Services.
					</strong>
				</p>
			</div>
		</>
	);
};

export default Terms;

import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";

import styles from "./Interactive3DCard.module.css";
import commonStyles from "../common/CommonStyles.module.css";
import swirls from "../../utils/swirls";

import phoenix from "../../assets/lotties/phoenix.json";
import phoenixStatic from "../../assets/images/phoenix_static.jpg";
import hoverMe from "../../assets/gifs/hover_me.gif";
import { useNavigate } from "react-router-dom";

const Interactive3DCard = ({ children, ...props }) => {
	const [isHovering, setIsHovering] = useState(false);
	const [isTouching, setIsTouching] = useState(false);
	const [isTouchedOnce, setIsTouchedOnce] = useState(false);
	const [showHoverMe, setShowHoverMe] = useState(true);
	const phoenixRef = useRef(null);

	const navigate = useNavigate();

	const handleHover = (e) => {
		if (!isTouchedOnce) {
			setIsTouchedOnce(true);
			return;
		}

		props.setIsHovering(true);
		setShowHoverMe(false);

		if (e.clientX) {
			setIsHovering(true);
		} else {
			setIsTouching(true);
		}
		let target_div = document.getElementById(styles.interactive_card);
		let offsets = target_div.getBoundingClientRect();
		let rec_top = offsets.top;
		let rec_left = offsets.left;
		let x_sensitivity = 15;
		let y_sensitivity = 30;

		let clientX = e.clientX || e.touches[0].clientX;
		let clientY = e.clientY || e.touches[0].clientY;

		let x = clientX - rec_left - target_div.clientWidth / 2; //x position within the element.
		let y = clientY - rec_top - target_div.clientHeight / 2; //y position within the element.

		let x_rot = x / x_sensitivity;
		let y_rot = y / y_sensitivity;

		phoenixRef.current.goToAndStop(
			Math.max(
				0,
				Math.min(
					Math.round(((clientX - rec_left) / target_div.clientWidth) * 80),
					79
				)
			),
			true
		);

		target_div.style.transform =
			"translatez(100px) scale(0.9)" +
			"rotateY(" +
			x_rot +
			"deg) rotateX(" +
			-y_rot +
			"deg)";
	};

	const resetHover = (e) => {
		if (e.clientX) {
			setIsHovering(false);
		} else {
			setIsTouching(false);
		}
		props.setIsHovering(false);
		setIsTouchedOnce(false);
		let target_div = document.getElementById(styles.interactive_card);
		target_div.style.transform = "translatez(0) rotateY(0) rotateX(0)";
		phoenixRef.current.goToAndStop(0, true);
	};

	let nextStep, dotValue;

	const speed = 0.005; // 0 < speed < 1
	const velocity = 5; // velocity > 0

	const handleDownloadClick = async (e) => {
		dotValue = 0; // Reset position
		cancelAnimationFrame(nextStep);
		elastic();
		await new Promise((resolve) => setTimeout(resolve, 500));
		navigate("/waitlist");
	};

	const elastic = () => {
		const elasticScale =
			(Math.pow(2, -10 * dotValue) *
				Math.sin((dotValue * 10 - 0.75) * velocity) +
				1) *
			100;
		let download_button = document.getElementById(styles.download_button);
		if (!download_button) return;
		download_button.style.transform = "scale(" + elasticScale + "%)";

		dotValue += speed;
		if (dotValue <= 1) {
			nextStep = requestAnimationFrame(elastic);
		}
	};

	useEffect(() => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			console.log("mobile");
			swirls.setup(styles.content__canvas);
			return () => {
				swirls.cleanup();
			};
		}
	}, []);

	return (
		<>
			<div
				className={styles.content__canvas}
				style={{ opacity: isHovering || isTouching ? 1 : 0 }}
			></div>
			<div className={styles.wrapper}>
				<div
					id={styles.interactive_card}
					className={styles.interactive_card}
					style={
						isHovering || isTouching ? {} : { border: "0px solid transparent" }
					}
					onMouseMove={handleHover}
					onMouseOut={resetHover}
					onTouchMove={handleHover}
					onTouchEnd={resetHover}
				>
					<div
						className={styles.card_shape}
						style={
							isHovering || isTouching
								? {
										transform: "translateZ(-10px) scale(1.01)",
								  }
								: { display: "none" }
						}
					></div>
					<div
						style={{
							position: "absolute",
							top: "0px",
							left: "0px",
							width: "100%",
							height: "100%",
							transform: "translateZ(72px) scale(0.9)",
						}}
						onMouseMove={handleHover}
						onMouseOut={resetHover}
						onTouchMove={handleHover}
						onTouchEnd={resetHover}
					/>
					<div
						className={styles.top_heading}
						style={isHovering || isTouching ? { opacity: 1 } : { opacity: 0 }}
					>
						<strong className={commonStyles.gradient_text}>ae</strong>
						Shakespeare
					</div>
					<div className={styles.image_placeholder}>
						<Lottie
							animationData={phoenix}
							lottieRef={phoenixRef}
							autoplay={false}
							style={isHovering || isTouching ? { opacity: 1 } : { opacity: 0 }}
						/>
						<img
							src={phoenixStatic}
							alt="phoenix"
							style={isHovering || isTouching ? { opacity: 0 } : { opacity: 1 }}
						/>
						{showHoverMe && (
							<img
								src={hoverMe}
								className={styles.hover_me_icon}
								alt="hover me"
								style={{
									opacity: isHovering || isTouching ? 0 : 1,
								}}
							/>
						)}
					</div>
					<div
						className={styles.body_text}
						style={isHovering || isTouching ? { opacity: 1 } : { opacity: 0 }}
					>
						We create immersive experiences in the Metaverse. <br />
						Witness <strong className={commonStyles.gradient_text}>ae</strong>
						Experiences
					</div>
					<div
						className={styles.buttons_wrapper}
						style={
							isHovering || isTouching
								? {
										opacity: 0,
										height: "0px",
										justifyContent: "center",
										alignItems: "center",
								  }
								: {
										bottom: window.matchMedia("(max-width: 768px)").matches
											? "70px"
											: "135px",
										opacity: 1,
										justifyContent: "center",
										alignItems: "center",
								  }
						}
					>
						<div
							id={styles.download_button}
							className={styles.download_button}
							onClick={handleDownloadClick}
						>
							Request Demo!
						</div>
					</div>
					{window.matchMedia("(min-width: 768px)").matches && (
						<div
							className={styles.buttons_wrapper}
							style={
								isHovering
									? { opacity: 1 }
									: { bottom: "135px", opacity: 0, height: "0px" }
							}
						>
							<a
								href="/waitlist"
								style={{
									all: "unset",
								}}
							>
								<div className={styles.join_waitlist_button}>Request Demo!</div>
							</a>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Interactive3DCard;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

import swirls from "../../utils/swirls";
import TypedText from "../common/TypedText";
import styles from "./WaitlistForm.module.css";

import googlePlay from "../../assets/images/google_play.png";
import apple from "../../assets/images/apple.png";
import RotatingLinesLoader from "../common/RotatingLinesLoader";

const WaitlistForm = () => {
	const [inputs, setInputs] = useState({});
	const [isJoining, setIsJoining] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);

	const typedRef = useRef(null);

	let nextStep, dotValue;

	const speed = 0.012; // 0 < speed < 1
	const velocity = 4; // velocity > 0

	const elastic = () => {
		const elasticScale =
			(Math.pow(2, -25 * dotValue) *
				Math.sin((dotValue * 10 - 0.75) * velocity) +
				1) *
			100;
		let download_button = document.getElementById(styles.submit_button);
		if (!download_button) return;
		download_button.style.transform =
			"translateX(-50%) scale(" + elasticScale + "%)";

		dotValue += speed;
		if (dotValue <= 1) {
			nextStep = requestAnimationFrame(elastic);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		dotValue = 0; // Reset position
		cancelAnimationFrame(nextStep);
		elastic();

		setIsJoining(true);

		try {
			if (!inputs.email || !inputs.phone) {
				toast.error("Please fill all the fields!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsJoining(false);
				return;
			}

			const emailRegex = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
			const phoneRegex = "^[0-9]{10}$";

			if (!inputs.email.match(emailRegex)) {
				toast.error("Please enter a valid email!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsJoining(false);
				return;
			}

			if (!inputs.phone.match(phoneRegex)) {
				toast.error("Please enter a valid phone number!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsJoining(false);
				return;
			}

			let data = inputs;

			if (sessionStorage.getItem("referral")) {
				data.referredBy = sessionStorage.getItem("referral");
			}

			await axios.post("/leads", data, {
				headers: {
					"x-auth-token": process.env.REACT_APP_MAIL_TOKEN,
				},
			});
			toast.success("Joined the waitlist!", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});
			setShowThankYou(true);
		} catch (err) {
			if (err.response && err.response.status === 400) {
				toast.error("Already joined the waitlist!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
			} else {
				toast.error("Something went wrong, please try again later.", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
			}
		}

		setIsJoining(false);
		setInputs({});
	};

	useEffect(() => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			swirls.setup(styles.content__canvas);
			return () => {
				swirls.cleanup();
			};
		}
	}, []);

	return (
		<div className={styles.body}>
			<div className={styles.content__canvas} />
			<div className={styles.form_wrapper}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						boxSizing: "border-box",
						marginBottom: "20px",
					}}
				>
					{!showThankYou && (
						<TypedText
							ref={typedRef}
							strings={[
								"Join AE future of Artificial Intelligence",
								"Get notified when we launch",
								"Convert your restaurant",
							]}
							loop={true}
							typeSpeed={45}
							backSpeed={45}
							backDelay={1000}
							style={{
								color: "white",
								fontSize: "30px",
								fontWeight: "bold",
							}}
						/>
					)}
				</div>
				{showThankYou ? (
					<div className={styles.thank_you_block}>
						<h1>Thank you for joining the Waitlist!</h1>
						<h2>You will receive a confirmation email shortly.</h2>
					</div>
				) : (
					<div className={styles.form_block}>
						<div className={styles.input_flexbox}>
							<label>Email</label>
							<input
								name="email"
								type="email"
								className={styles.input_field}
								value={inputs.email || ""}
								placeholder="youremail@mail.com"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							<label>Phone</label>
							<input
								type="number"
								min={0}
								maxLength={10}
								name="phone"
								value={inputs.phone || ""}
								className={styles.input_field}
								placeholder="Phone Number"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox} style={{ fontSize: "20px" }}>
							<button
								id={styles.submit_button}
								className={styles.submit_button}
								onClick={isJoining ? null : handleSubmit}
							>
								{isJoining ? (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "row",
										}}
									>
										<RotatingLinesLoader width="20px" strokeColor="black" />
										&nbsp; Please Wait...
									</div>
								) : (
									"Join"
								)}
							</button>
						</div>
					</div>
				)}
				<div className={styles.cta}>
					<p>Soon available on:</p>
					<a href="https://play.google.com/store/apps/details?id=vision.shakespeare.shakespeareAE" className={styles.button}>
						<img
							src={googlePlay}
							className={styles.download_logo}
							alt="Google Play Icon"
						/>
						Google Play
					</a>
					<a href="https://apps.apple.com/app/shakespeare-view-future/id645123515" className={styles.button}>
						<img
							src={apple}
							className={styles.download_logo}
							alt="Apple Icon"
						/>
						App Store
					</a>
				</div>
			</div>
		</div>
	);
};

export default WaitlistForm;

import React, { useEffect, useState } from "react";
import axios from "axios";

import styles from "./Home.module.css";
import commonStyles from "../common/CommonStyles.module.css";

import shakespeareLogo from "../../assets/images/shakespeare_logo.png";
import twitter from "../../assets/images/twitter.png";

import Interactive3DCard from "./Interactive3DCard";
import RotatingLinesLoader from "../common/RotatingLinesLoader";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Home = () => {
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [isHovering, setIsHovering] = useState(false);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const referral = searchParams.get("ref");

	if (referral) {
		sessionStorage.setItem("referral", referral);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);

		try {
			if (!email || !phone) {
				toast.error("Please fill all the fields!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (
				!email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
			) {
				toast.error("Please enter a valid email!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (!phone.match("$[0-9]{10}^")) {
				toast.error("Please enter a valid phone number!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			}

			let data = { phone, email };

			if (sessionStorage.getItem("referral")) {
				data = {
					...data,
					referredBy: sessionStorage.getItem("referral"),
				};
			}

			await axios.post("/leads", data, {
				headers: {
					"x-auth-token": process.env.REACT_APP_MAIL_TOKEN,
				},
			});

			setIsSubmitting(false);
			setShowThankYou(true);
		} catch (err) {
			toast.error("Something went wrong, please try again later.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});

			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		document.getElementsByTagName("body")[0].style.overflow = "hidden";
		return () => {
			document.getElementsByTagName("body")[0].style.overflow = "auto";
		};
	}, []);

	return (
		<div className={styles.body}>
			<img
				src={shakespeareLogo}
				className={styles.logo}
				alt="Shakespeare Logo"
			/>
			<div className={styles.header}>
				<h2 className={[styles.heading, commonStyles.gradient_text].join(" ")}>
					Shakespeare AE
				</h2>
				<h3 className={styles.subheading}>
					Future of Artificial Intelligence & Augmented Reality
				</h3>
			</div>
			{window.matchMedia("(max-width: 768px)").matches ? (
				<div
					className={styles.action_row}
					style={{
						opacity: isHovering ? 0 : 1,
						transition: "0.3s",
					}}
				>
					<a href="https://journal.shakespeare.vision">
						<div className={styles.filled_button}>Journal</div>
					</a>
					<a
						href="https://twitter.com/intent/tweet?text=Discover%20the%20future%20of%20Artificial%20Intelligence%20%26%20Augmented%20Reality%20-%20The%20world%20of%20%E2%80%9CAlternative%20Emergence%20%28AE%29%E2%80%9D%0A%0AGet%20the%20aeExperience%20%40ShakespeareAE%0AJoin%20the%20waitlist%20now%20%E2%86%92%20shakespeare.vision"
						target="_blank"
						rel="noreferrer"
					>
						<div className={[styles.filled_button].join(" ")}>
							<img src={twitter} alt="Twitter" />
						</div>
					</a>
				</div>
			) : (
				<div
					className={styles.action_row}
					style={{
						opacity: isHovering ? 0 : 1,
						transition: "0.3s",
					}}
				>
					<a href="https://business.shakespeare.vision">Business</a>
					<a href="https://advertising.shakespeare.vision">Advertising</a>
					<a href="https://journal.shakespeare.vision">Journal</a>
					<a
						href="https://twitter.com/intent/tweet?text=Discover%20the%20future%20of%20Artificial%20Intelligence%20%26%20Augmented%20Reality%20-%20The%20world%20of%20%E2%80%9CAlternative%20Emergence%20%28AE%29%E2%80%9D%0A%0AGet%20the%20aeExperience%20%40ShakespeareAE%0AJoin%20the%20waitlist%20now%20%E2%86%92%20shakespeare.vision"
						target="_blank"
						rel="noreferrer"
					>
						<div className={[styles.filled_button].join(" ")}>
							<img src={twitter} alt="Twitter" />
						</div>
					</a>
				</div>
			)}
			<Interactive3DCard setIsHovering={setIsHovering} />
			<div
				className={styles.cta_wrapper}
				style={{
					opacity: isHovering ? 0 : 1,
					transition: "0.3s",
				}}
			>
				<div className={styles.filled_button}>
					<a href="https://advertising.shakespeare.vision">Advertising</a>
				</div>
				<div className={styles.filled_button}>
					<a href="https://business.shakespeare.vision">Business</a>
				</div>
			</div>
			<div className={styles.contact_block}>
				{showThankYou ? <h2>Thank You!</h2> : "Get in Touch!"}
				{!showThankYou && (
					<>
						<input
							name="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className={[styles.input, styles.top].join(" ")}
						/>
						<input
							name="phone"
							placeholder="Phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							className={[styles.input, styles.bottom].join(" ")}
						/>
					</>
				)}
				<button
					className={styles.submit_button}
					onClick={isSubmitting || showThankYou ? null : handleSubmit}
				>
					{isSubmitting ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
							}}
						>
							<RotatingLinesLoader width="20px" strokeColor="white" /> &nbsp;
							Please Wait...
						</div>
					) : showThankYou ? (
						"Submitted!"
					) : (
						"Submit"
					)}
				</button>
			</div>
			<div className={styles.footer}>
				<Link to={"/privacy"}>
					Privacy & Policy
				</Link>
			</div>
		</div>
	);
};

export default Home;

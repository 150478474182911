import React from 'react'

export default function EAP() {
    window.location.href="https://lazylinuxer.github.io/eap"
  return (
    <div style={{
        width:"100%",
        height:"100vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }}>Loading...</div>
  )
}
